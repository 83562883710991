import { closeIcon, heartIcon, infoIcon, playIcon } from "components/base/SVG";
import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import BonusSelect from "components/inputs/BonusSelect";
import { bonusSelectList } from "utils/constantLists";
import { formatCurrency } from "utils/globalFunc";
import { claimBonusHandler, getBonusData } from "services/bonusService";
import offersPic1 from "assets/images/offers/1.png";
import offersPic2 from "assets/images/offers/2.png";
import offersPic3 from "assets/images/offers/3.png";
import offersPic4 from "assets/images/offers/4.png";
import offersPic5 from "assets/images/offers/5.png";
import { useSelector } from "react-redux";
import { handleToast } from "utils/toasts";

export default function BonusModal({ setModal, bonusData, setBonusData }) {
  const auth = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [select, setSelect] = useState(
    bonusSelectList?.filter(
      (filterItem) =>
        filterItem?.value?.toLowerCase() === bonusData?.toLowerCase()
    )[0]
  );
  const [selectedBonus, setSelectedBonus] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [bonusList, setBonusList] = useState([]);
  const [checked, setChecked] = useState(false);
  const handleClaim = async () => {
    setDisabled(true);
    let data = await claimBonusHandler(selectedBonus?.id, auth?.isLogged);
    if (data === false) {
      setDisabled(false);
      return;
    }
    handleToast("success", data?.message);
    setDisabled(false);
    setBonusData(null);
    setModal("bonusSuccess");
  };
  const getBonusList = async () => {
    let data = await getBonusData();
    if (data === false) {
      return;
    }
    setBonusList(data);
  };

  useEffect(() => {
    getBonusList();
  }, []);

  return (
    <div className="modalBonus">
      <div className="modalBonus__close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalBonus__title">
        <h6>Select your Bonus</h6>
      </div>
      <div className="modalBonus__action">
        <BonusSelect
          list={bonusSelectList}
          selected={select}
          onChange={(data) => {
            setSelect(data);
            setSelectedBonus(null);
            setShow(false);
            setChecked(false);
          }}
        />
        <div className="modalBonus__action-btns">
          <button
            type="button"
            className="modalBonus__action-btn"
            onClick={() => setShow(true)}
          >
            <p>
              I'm registered under code "<span>Foss</span>"
            </p>
          </button>
          <h6>or</h6>

          <a
            href={
              select?.value?.toLowerCase() === "packdraw"
                ? "https://packdraw.com/?ref=foss"
                : "https://roobet.com/?ref=foss"
            }
            target="_blank"
            className="modalBonus__action-btn register"
          >
            <p>
              Register <span>now !</span>
            </p>
          </a>
        </div>
      </div>
      {show && (
        <div className="modalBonus__footer">
          <div className="modalBonus__footer-box">
            <h6>Select your bonus</h6>
            <div className="modalBonus__footer-items">
              {bonusList
                ?.filter(
                  (filterItem) =>
                    filterItem?.bonusCategory?.toLowerCase() ===
                    select?.value?.toLowerCase()
                )
                ?.map((item, index) => {
                  return (
                    <div
                      className={
                        "modalBonus__footer-item " +
                        (selectedBonus?.id === item?.id ? "active" : "")
                      }
                      key={index}
                      onClick={() => {
                        if (selectedBonus?.id === item?.id) {
                          setSelectedBonus(null);
                          setChecked(false);
                        } else {
                          setSelectedBonus(item);
                          setChecked(false);
                        }
                      }}
                    >
                      {item?.id === "roobet-100-freespins" && (
                        <img src={offersPic1} alt="image" />
                      )}
                      {item?.id === "roobet-4000-freespins" && (
                        <img src={offersPic2} alt="image" />
                      )}
                      {item?.id === "packdraw-10percent" && (
                        <img src={offersPic3} alt="image" />
                      )}
                      {item?.id === "packdraw-rolex-submarine" && (
                        <img src={offersPic4} alt="image" />
                      )}
                      {item?.id === "packdraw-iphone-16-pro-max" && (
                        <img src={offersPic5} alt="image" />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
          {selectedBonus && parseInt(selectedBonus?.requiredWager) > 0 && (
              <div
                  className="modalBonus__footer-desc"
                  onClick={() => setChecked(!checked)}
              >
                <div
                    className={
                        "modalBonus__footer-desc-elem " + (checked ? "active" : "")
                    }
                ></div>
                <p>
                  <span>
                    Wagered ${formatCurrency(selectedBonus?.requiredWager.toString())}
                  </span>{" "}{selectedBonus?.id === "packdraw-rolex-submarine" && " (in a 30 day period) "}
                  on {select?.value} under code "<b>Foss</b>"

                </p>
              </div>
          )}

          <button
              type="button"
              className="button play"
              onClick={handleClaim}
              disabled={
                  select === null ||
                  selectedBonus === null ||
                  !show ||
              disabled ||
              (parseInt(selectedBonus?.requiredWager) > 0 && !checked)
            }
          >
            <span className="default"></span>
            <span className="hov"></span>
            <span className="active"></span>
            <p>CLAIM NOW</p>
            {playIcon}
          </button>
        </div>
      )}
    </div>
  );
}
