import { handleError, instance } from "./api";

export const getLeaderBoardData = async () => {
  try {
    const result = await instance.get("/leaderboard");
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const getPackLeaderBoardData = async () => {
  try {
    const result = await instance.get("/leaderboard/packdraw");
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};

export const getShardsLeaderboardData = async () => {
  try {
    const result = await instance.get("/leaderboard/shards");
    if (result?.status === 200) {
      return result?.data;
    } else return false;
  } catch (error) {
    handleError(error);
    return false;
  }
};