import React, { useEffect, useState } from "react";
import { getShopData } from "services/shopService";
import TopPrizeCard from "./components/TopPrizeCard";
import bgImage2 from "assets/images/store/bgImage2.png";
import bgImage from "assets/images/store/bgImage.png";
import shardIcon from "assets/icons/shardIcon.png";
import kickIcon from "assets/images/store/kickIcon.svg";
import guessImage from "assets/images/store/guessImage.png";
import earnShard from "assets/images/store/earnShard.png";
import star from "assets/images/store/star.png";
import { gambleIcon, playIcon } from "components/base/SVG";
import PrizeCard from "./components/PrizeCard";
import ShopItemMod from "components/modals/ShopItemMod";
import Modal from "components/base/Modal";
import { AnimatePresence } from "framer-motion";
import {getShardsLeaderboardData} from "../../services/leaderboardService";
import {handleToast} from "../../utils/toasts";
import TopShardsHolder from "./components/TopShardsHolder";

export default function StorePage({ modal, setModal }) {
  const [modalData, setModalData] = useState(null);
  const [shopData, setShopData] = useState([]);
  const [topPrizes, setTopPrizes] = useState([]);
  const [leaderboardData, setLeaderboardData] = useState(null);

  const getLeaderBoard = async () => {
    const data = await getShardsLeaderboardData();
    if (data === false) {
      handleToast("error", "An error occured");
      return;
    }
    setLeaderboardData(data);
  };

  const getShop = async () => {
    let data = await getShopData();
    if (data === false) {
      return;
    }
    setShopData(
      data?.filter((filterItem) => filterItem?.shopRarity !== "HIGHLIGHTED")
    );
    setTopPrizes(
      data?.filter((filterItem) => filterItem?.shopRarity === "HIGHLIGHTED")
    );
  };
  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    getLeaderBoard();
  }, []);
  // console.log("shopData", shopData);
  // console.log("topPrizes", topPrizes);
  return (
    <>
      <main>
        <div className="store">
          <div className="store__image">
            <img src={bgImage2} alt="bgImage" />
          </div>
          <div className="store__image2">
            <img src={bgImage} alt="bgImage" />
          </div>
          <div className="store__star one">
            <img src={star} alt="star" />
          </div>
          <div className="store__star two">
            <img src={star} alt="star" />
          </div>
          <div className="store__star three">
            <img src={star} alt="star" />
          </div>
          {/* <div className="store__bg"></div> */}
          <div className="store__bg2"></div>
          <div className="auto__container">
            <div className="store__inner">
              <h1>Store</h1>
              <div className="store__top">
                <div className="store__top-title">
                  <h3>
                    Cherished <span>Prizes</span>
                  </h3>
                </div>
                <div className="store__top-items">
                  {topPrizes?.map((item, index) => {
                    return (
                      <TopPrizeCard
                        itemData={item}
                        key={index}
                        onClick={() => {
                          setModal("shopItem");
                          setModalData(item);
                        }}
                      />
                    );
                  })}
                </div>
                <div className="store__earn">
                  <div className="store__earn-shard one">
                    <img src={earnShard} alt="shard" />
                  </div>
                  <div className="store__earn-shard two">
                    <img src={earnShard} alt="shard" />
                  </div>
                  <div className="store__earn-shard three">
                    <img src={earnShard} alt="shard" />
                  </div>
                  <div className="store__earn-inner">
                    <div className="store__earn-info">
                      <h2>
                        How to earn{" "}
                        <img className="shard" src={shardIcon} alt="shard" />{" "}
                        <span>Shards on</span>{" "}
                        <img className="kickIcon" src={kickIcon} alt="kick" />
                      </h2>
                    </div>
                    <div className="store__earn-row">
                      <div className="store__earn-item">
                        <div className="store__earn-item-title">
                          {playIcon}
                          <h4>Watch Stream</h4>
                        </div>
                        <div className="store__earn-item-content">
                          <p>Shards will rain in the chat <span>randomly</span> or when <span>we win big!</span></p>
                        </div>
                      </div>
                      <div className="store__earn-item guess">
                        <div className="store__earn-item-title">
                          {gambleIcon}
                          <h4>Guess the Balance</h4>
                        </div>
                        <div className="store__earn-item-content">
                          <div className="store__earn-item-content-image">
                            <img src={guessImage} alt="wheel" />
                          </div>
                          <p>
                            Win shards from our guess the balance <span>wheel spins</span>
                          </p>
                        </div>
                      </div>
                      <div className="store__earn-item disabled">
                        <div className="store__earn-item-title">
                          {gambleIcon}
                          <h4>Gamble</h4>
                        </div>
                        <div className="store__earn-item-content">
                          <p>Coming soon</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="store__prize">
                  <div className="store__prize-inner">
                    <div className="store__prize-title">
                      <h3>
                        All <span>Prizes</span>
                      </h3>
                    </div>
                    <div className="store__prize-items">
                      {shopData?.map((item, index) => {
                        return (
                          <PrizeCard
                            itemData={item}
                            key={index}
                            onClick={() => {
                              setModal("shopItem");
                              setModalData(item);
                            }}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
                <TopShardsHolder users={leaderboardData} />
              </div>
            </div>
          </div>
        </div>
      </main>
      <AnimatePresence>
        {modal === "shopItem" && (
          <Modal setModal={setModal}>
            <ShopItemMod
              setModal={setModal}
              modalData={modalData}
              setModalData={setModalData}
            />
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
}
