import { motion, useAnimation } from "framer-motion";
import { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";

export default function FadeUp({ children, delay, className }) {
  const [ref, inView, entry] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  return (
    <motion.div
      animate={inView ? "visible" : "hidden"}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: {
          opacity: 0,
          y: 50,
        },
      }}
      ref={ref}
      transition={{
        delay: delay,
        duration: 0.6,
        ease: "easeOut",
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
}
