import { closeIcon, infoIcon } from "components/base/SVG";
import React from "react";
import { useTranslation, Trans } from "react-i18next";

export default function VipPageMod({ setModal }) {
  const { t } = useTranslation();

  return (
    <div className="modalJackpot">
      <div className="modalJackpot__close" onClick={() => setModal(null)}>
        {closeIcon}
      </div>
      <div className="modalJackpot__icon">{infoIcon}</div>
      <div className="modalJackpot__inner">
        <div className="modalJackpot__head">
          <h3>Disclaimer</h3>
        </div>
        <div className="modalJackpot__rows">
          <div className="modalJackpot__row">
            <p>
              Wager on blackjack, roulette &amp; Roobet house games is excluded
              from your progress towards VIP Rank Ups due to the severe amount
              of wager abuse potential. Our VIP rewards are tailored towards
              slot players and sports bettors.
              <br />
              <br />
              Wager from blackjack, roulette, and Roobet house games will count
              towards our monthly leaderboard, but at a weighted percentage to
              avoid wager abuse:
              <br />
              <br />• Games with an RTP of <strong>97% or less</strong> will
              contribute <strong>100%</strong> of the amount wagered to the
              leaderboard.
              <br />• Games with an RTP <strong>above 97%</strong> will
              contribute <strong>50%</strong> of the amount wagered to the
              leaderboard.
              <br />• Games with an RTP of <strong>98% and above</strong> will
              contribute <strong>10%</strong> of the amount wagered to the
              leaderboard.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
