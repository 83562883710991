import React from "react";
import { useTranslation } from "react-i18next";
import FadeUp from "components/base/FadeUp";
import { formatNumberComma } from "utils/globalFunc";
import crown from "assets/images/jackpot/crown.png";
import earnShard from "assets/images/store/earnShard.png";

export default function TopShardsHolder({ users }) {
  const { t } = useTranslation();

  return (
    <div className="ticketh">
      <div className="auto__container">
        <div className="ticketh__inner">
          <h2>Top Shards Holders</h2>
          <div className="ticketh__table">
            <div className="ticketh__table-body">
              {users?.slice(0, 50)?.map((item, index) => (
                <TicketHoldersItem
                  itemData={item}
                  key={index}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const TicketHoldersItem = ({ itemData, index }) => {
  const { t } = useTranslation();

  return (
    <FadeUp className="ticketh__table-row shards" delay={0.3}>
      <div className="ticketh__table-number">
        <p>{index + 1}</p>
      </div>
      <div className="ticketh__table-items shards">
        <div className="ticketh__table-item">
          <p>{itemData?.username}</p>
          {index === 0 && (
              <img src={crown} className="crownImg" alt={t('ticketHolders.ticketImageAlt')}/>
          )}
        </div>
        <div className="ticketh__table-item">
          <p></p>
        </div>
        <div className="ticketh__table-item">
          <div className="ticketh__table-item-wrapper">
            <div className="ticketh__table-item-tickets">
              <img className="shards" src={earnShard} alt="shard"/>
              <h6>
                {formatNumberComma(
                    Math.round(itemData?.shards).toString()
                )}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </FadeUp>
  );
};
